<template>
	<div class="lp">
		<h1 class="lp__header"><i class="sprite lp_scratch2021_title" title="スクラッチで限定壁紙プレゼント" /></h1>

		<!-- イントロ -->
		<section class="lp__intro">
			<p class="lp__intro--text">スクラッチの中に限定壁紙が隠れているよ。削って、壁紙をGETしよう！</p>
		</section>
		<!-- /イントロ -->

		<!-- スクラッチ -->
		<section class="lp__contents">
			<div :class="[
				'lp__contents--inner',
				`is-status0${status}`,
				`is-scratch0${animetionType}`
			]">
				<img src="@/assets/img/lp_scratch2021/wall_2160_1920.png" class="lp__contents--wallpaper" />
				<i class="sprite lp_scratch2021_wall01 lp__contents--wall01" />
				<i class="sprite lp_scratch2021_wall00 lp__contents--wall00" />
				<img v-show="!isDisabled" src="@/assets/img/lp_scratch2021/lp_scratch2021_pointer.svg" class="lp__contents--pointer" v-cloak />
			</div>

			<a v-if="status !== 2" href="javascript:void(0);" :id="status === 0 ? 'trackingScratchBtn00' : 'trackingScratchBtn01'" class="lp__contents--btn" @click="onClick($event)" v-cloak>
				<i v-show="isDisabled" class="sprite lp_scratch2021_btn_off" title="スクラッチを削る" />
				<i v-show="!isDisabled" class="sprite lp_scratch2021_btn_on" title="スクラッチを削る" />
			</a>

			<transition name="fade">
				<div v-show="status === 2" class="lp__contents--maro">
					<i class="sprite lp_scratch2021_img" />
					<p>限定壁紙GETしたよ！画像を長押ししてダウンロードしてニャ！</p>
				</div>
			</transition>
		</section>
		<!-- /スクラッチ -->

		<!-- マイページ登録 -->
		<aside class="lp__mypage" v-if="!isMyPage">
			<p class="lp__mypage--text"><em>マイページ登録</em>をすると簡単にアクセスできるよ！</p>

			<div class="sugotoku-lp__mypage">
				<div class="balloon__wrapper">
					<div class="balloon">
						<div class="balloon__inner">
							<div class="balloon__text--wrapper">
								<p class="balloon__text--title">とっても便利！</p>
								<p class="balloon__text">マイページ登録をすると、気圧予報や新着情報が毎日簡単にチェックできるよ&#9834;</p>
							</div>
							<div class="balloon__img--wrapper">
								<i class="sprite__mypage-button mypage-img" />
							</div>
						</div>
						<div class="balloon__close" @click="clickCancelMypage($event)">
							<i class="sprite__mypage-button close-icon" />
						</div>
					</div>
				</div>
				<div :class="[
					'dcm_favorite_separateButton',
					'dcm_typeRed',
					'mypage__button',
					isLocalClass,
				]">
					<p>
						<a @click.stop="clickMypageButton($event)"><span>マイページに追加</span></a>
					</p>
				</div>
			</div>
		</aside>
		<!-- /マイページ登録 -->

		<!-- アウトロ -->
		<div class="lp__appdownload growthhack-f">
			<h2 class="growthhack-f__title">
				<i class="growthhack-f__title--img sprite_growthhack-f img01" />
				<p class="growthhack-f__title--text">あなたの<span class="is-large">その不調、<br>低気圧のせい</span>かも!?</p>
			</h2>
			<div class="growthhack-f__symptoms">
				<img src="@/assets/img/growthhack/about_intro_img01.png" alt="頭痛 落ち込み 肩こり だるさ むくみ" class="growthhack-f__symptoms--img">
			</div>
			<div class="about-rightnow__login">
				<p class="about-rightnow__login--text">頭痛ーるの気圧予報で<br>体調不良になりやすい日がわかるよ！</p>
				<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="about-rightnow__login--button">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
				<a v-else @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="about-rightnow__login--button">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
			</div>
		</div>
		<div class="lp__back" @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })">TOPにもどる</div>
		<!-- /アウトロ -->

		<!-- モーダル -->
		<v-ons-modal :visible="isVisibleModal" animation="fade" animationOptions="{duration: 0.2, timing: 'ease-in'}" class="lp-modal">
			<aside class="lp-modal__inner">
				<div class="lp-modal__body">
					<i class="sprite lp_scratch2021_modal" title="今日はここまで！" />
					<p class="lp-modal__body--text">もう１回削ると、壁紙がもらえるよ！また明日も待ってるニャ！</p>
				</div>
				<a class="lp-modal__button" @click="clickModalLink($event)">
					<ons-icon icon="md-close" />表示を閉じる
				</a>
			</aside>
		</v-ons-modal>
		<!-- /モーダル -->
	</div>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'
import { SET_USER_DATA, SET_IS_MY_PAGE } from '@/store/modules/common/mutation-types'

// ライブラリ
import cmnConst from '@/assets/js/constant.js'
import moment from 'moment'

export default {
	name: 'LpScratch2021',
	components: {
	},
	data () {
		return {
			backendUrl: cmnConst.BACKEND_URL,
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			isComplete: false,
			isDisabled: false,
			animetionType: 0,
			status: 0,
			isVisibleModal: false,
			isLocalClass: '',
			accessDate: null,
			scratchDate: null
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['userId', 'userToken', 'userData', 'isMyPage', 'isAndroid'])
	},
	mounted () {
		this.isLocalClass = process.env.VUE_APP_PLATFORM === 'dev_sugotoku' ? 'is-local' : ''

		if (!this.userData) {
			this.SET_USER_DATA({
				user_id: this.userId,
				user_token: this.userToken
			}).then(res => {
				console.log('userData', this.userData)
				this.init()
			}).catch(error => {
				console.log('Error SET_USER_DATA: ', error)
			})
		} else {
			this.init()
		}
	},
	beforeDestroy () {
	},
	methods: {
		// map Vuex actions
		...mapActions('common', ['updateWebStorage', SET_USER_DATA, SET_IS_MY_PAGE]),

		init () {
			const offset = '+09:00'
			this.accessDate = moment(new Date()).utcOffset(offset).format('YYYYMMDD')

			// Webstrageをチェック
			const webStorage = this.userData.teigaku.web_storage && this.userData.teigaku.web_storage.data ? JSON.parse(this.userData.teigaku.web_storage.data) : {}
			this.status = webStorage.sc_cnt ? webStorage.sc_cnt : 0
			this.scratchDate = webStorage.sc_date ? webStorage.sc_date : null
			// console.log(this.status)
			// console.log(this.scratchDate)
			if (this.status === 2 || this.accessDate === this.scratchDate) this.isDisabled = true
		},

		onClick (e) {
			e.preventDefault()

			// disabledなら何もしない
			if (this.isDisabled) return false
			this.animetionType = this.status + 1

			// NOTE: ユーザーに意識させずバックグラウンドで行うためローディングやエラー表示は行わない
			this.updateWebStorage({
				user_id: this.userId,
				user_token: this.userToken,
				data: { 'sc_cnt': this.status + 1, 'sc_date': this.accessDate }
			}).then(res => {
				// NOTE: アニメーション後の処理
				setTimeout(() => {
					if (this.animetionType === 1) this.isVisibleModal = true
					switch (this.animetionType) {
						case 1:
							this.isVisibleModal = true
							break
						case 2:
							this.isDisabled = true
							this.status = 2
							break
					}
				}, 2000)

				// NOTE: userDataの更新
				return this.SET_USER_DATA({
					user_id: this.userId,
					user_token: this.userToken
				})
			}).catch(error => {
				// NOTE: エラーの処理
				console.log(error)
			})
		},

		clickModalLink (e) {
			e.preventDefault()
			this.isVisibleModal = false
			this.isDisabled = true
		},

		clickCancelMypage (e) {
			e.preventDefault()
			this.SET_IS_MY_PAGE(true)
			// CHANGED: コンテンツの高さを確保する処理の見直しのためコメントアウト
			// this.$nextTick(() => {
			// 	this.$emit('contentHeight')
			// })
		},

		// NOTE: マイページ登録ボタンクリック処理
		clickMypageButton (e) {
			// NOTE: loadイベントでマイページ登録ボタンクリックのイベントリスナーを登録しているため、ページ読み込み時に「.dcm_favorite_separateButton a」がない場合はイベント発火しない。そのためフッターボタンのclickイベントを発火してマイページ登録する
			const footerMypageButton = document.getElementById('dcm_bookmarkArea')
			if (footerMypageButton) footerMypageButton.click()
			// マイページ登録イベントがある場合、2重処理になり動作がおかしくなるため、他のリスナー、デフォルトのイベントをキャンセルする
			e.stopImmediatePropagation()
			e.preventDefault()
			return false
		}
	}
}
</script>

<style lang="scss" scoped>
@import "~@/assets/sass/variable";

// NOTE: LPページ限定の変数（デザインシステム外のため他での利用禁止）
$lp-text: #333;
$lp-primary-color: #FD4000;
$font-size-13: 0.812rem;
$font-size-15: 0.937rem;
$line-height-1_4: 1.4;
$spacing-12: 0.75rem;
$spacing-30: 1.875rem;
$spacing-45: 2.812rem;
$sugotoku-mypage-red: #d2193f;

.lp {
	overflow: hidden;
	color: $lp-text;
	font-family: $lp-family-primary;
	background-color: $background-primary;

	&__header {
		position: relative;
		margin: 0;
		background-color: $lp-primary-color;

		@media (max-width: (360px)) {
			padding-top: 70px;
		}

		> i {
			margin: 0 auto;

			@media (max-width: (360px)) {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: scale(.853) translate(-50%, -50%);
				transform-origin: 0 0;
			}
		}
	}

	&__intro {
		margin: $spacing-10 0 0 0;
		padding: 0 $spacing-10;

		&--text {
			margin: 0;
			font-size: $font-size-15;
			line-height: $line-height-1_4;
		}
	}

	.lp__contents {
		&--inner {
			position: relative;
			width: 289px;
			height: 257px;
			margin: $spacing-10 auto 0;
			border: solid 10px $lp-primary-color;
		}

		&--wall00,
		&--wall01 {
			position: absolute;
			top: 0;
			left: 0;
		}

		&--pointer {
			position: absolute;
			top: 20px;
			left: 12px;
		}

		&--wallpaper {
			max-width: 100%;
			-webkit-touch-callout: default;
		}

		&--btn {
			display: block;
			width: 240px;
			margin: $spacing-20 auto 0;
		}

		// 最初
		&--inner.is-scratch01 {
			.lp__contents--pointer { animation: scratch01 1.8s ease-in-out 0s forwards; }
			.lp__contents--wall00 { animation: fadeout01 1.8s ease-in-out 0s forwards; }
		}

		// 2回目
		&--inner.is-status01 {
			.lp__contents--pointer {
				top: 50%;
				left: 200px;
				transform: translateY(-50%);
			}
			.lp__contents--wall00 { display: none; }
		}

		&--inner.is-scratch02 {
			.lp__contents--pointer { animation: scratch02 1.8s ease-in-out 0s forwards; }
			.lp__contents--wall01 { animation: fadeout01 1.8s ease-in-out 0s forwards; }
		}

		// 完了後
		&--inner.is-status02 {
			.lp__contents--pointer { display: none; }
			.lp__contents--wall00 { display: none; }
			.lp__contents--wall01 { display: none; }
		}

		&--maro {
			position: relative;
			width: 336.5px;
			padding-top: 59px;
			margin: $spacing-12 auto 0;

			& > i,
			& > p {
				position: absolute;
				margin: 0;
			}

			& > i {
				top: 0;
				left: 0;
			}

			& > p {
				width: 226px;
				top: 50%;
				left: $spacing-16;
				transform: translateY(-50%);
				font-size: $font-size-15;
				line-height: $line-height-1_4;
			}

			@media (max-width: (360px)) {
				transform: scale(.853);
				transform-origin: 50% 50%;
				margin: $spacing-12 0 0;
			}
		}
	}

	&__mypage {
		margin: $spacing-16 0 0 0;

		&--text {
			margin: 0;
			padding: 0 $spacing-10;
			font-size: $font-size-15;
			line-height: $line-height-1_4;
			text-align: center;

			& > em {
				font-style: normal;
				font-weight: bold;
				color: $sugotoku-mypage-red;
			}

			@media (max-width: (360px)) {
				font-size: $font-size-13;
			}
		}
	}

	&__appdownload {
		margin: $spacing-45 auto $spacing-30;
	}

	&__back {
		display: block;
		appearance: none;
		color: $text-seconday;
		font-size: $font-size-24;
		background: $button-primary;
		border-radius: 27px;
		width: 74.6667%;
		margin: 0 auto;
		padding: 10px 10px 8px;
		text-align: center;
		text-decoration: none;
	}
}

.lp-modal {
	position: fixed;

	/deep/ .modal__content {
		padding-top: 75px;
		vertical-align: top;
	}

	&__inner {
		width: 300px;
		margin: 0 auto;
		padding-top: $spacing-20;
		background: $background-primary;
		box-sizing: border-box;
	}

	&__body {
		width: 260px;
		margin: 0 auto;
		padding: $spacing-30 0;
		background-color: $primary;
		box-sizing: border-box;
		border-radius: 20px;

		&--text {
			font-family: $lp-family-primary;
			margin: 0;
			padding: 0 $spacing-16;
			font-size: $font-size-15;
			line-height: $line-height-1_4;
		}
	}

	&__button {
		display: block;
		width: 100%;
		padding: $spacing-12 0;
		margin: $spacing-20 0 0 0;
		background: $background-primary;
		text-align: center;
		color: $text-primary;
		font-size: $font-size-12;

		& > ons-icon {
			font-size: $font-size-16;
			vertical-align: middle;
			margin-right: $spacing-8;
		}
	}
}

// クローク
[v-cloak] {
	display: none;
}

// トランジション
.fade-enter-active, .fade-leave-active {
	transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}

// アニメーション
@keyframes scratch01 {
	0%   {
		top: 0;
		left: 0;
	}
	48%  {
		top: 0;
		left: 200px;
		transform: none;
	}
	52%  {
		top: 50%;
		left: 0;
		transform: translateY(-50%);
	}
	100% {
		top: 50%;
		left: 200px;
		transform: translateY(-50%);
	}
}

@keyframes scratch02 {
	0%   {
		top: 50%;
		left: 200px;
		transform: translateY(-50%);
	}
	48%  {
		top: 166px;
		left: 0;
		transform: none;
	}
	52%  {
		top: 166px;
		left: 0;
		transform: none;
	}
	100% {
		top: 166px;
		left: 200px;
		transform: none;
	}
}

@keyframes fadeout01 {
	0%   {
		opacity: 1;
	}
	65%  {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

// スプライト
.sprite {
	background-image: url(~@/assets/img/lp_scratch2021/sprite_lp_scratch2021.png);
	background-size: (760px / 2) (1862px / 2);
	background-repeat: no-repeat;
	display: block;
}

.sprite.lp_scratch2021_img {
	width: (673px / 2);
	height: (118px / 2);
	background-position: (-5px / 2) (-5px / 2);
}

.sprite.lp_scratch2021_modal {
	width: (520px / 2);
	height: (334px / 2);
	background-position: (-5px / 2) (-133px / 2);
}

.sprite.lp_scratch2021_title {
	width: (750px / 2);
	height: (140px / 2);
	background-position: (-5px / 2) (-477px / 2);
}

.sprite.lp_scratch2021_wall00 {
	width: (578px / 2);
	height: (514px / 2);
	background-position: (-5px / 2) (-627px / 2);
}

.sprite.lp_scratch2021_wall01 {
	width: (578px / 2);
	height: (514px / 2);
	background-position: (-5px / 2) (-1151px / 2);
}

.sprite.lp_scratch2021_btn_off {
	width: (480px / 2);
	height: (86px / 2);
	background-position: (-5px / 2) (-1675px / 2);
}

.sprite.lp_scratch2021_btn_on {
	width: (480px / 2);
	height: (86px / 2);
	background-position: (-5px / 2) (-1771px / 2);
}
</style>
